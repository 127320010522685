import { NgModule } from '@angular/core';
import { FullScreenDialogModule, PlaceholderModule } from '@headpower/components';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';

import { SharedModule } from '../shared/shared.module';
import { ImageParagraphComponent } from './components/paragraphs/image-paragraph/image-paragraph.component';
import { InstructionComponent } from './components/instruction/instruction.component';
import { TextParagraphComponent } from './components/paragraphs/text-paragraph/text-paragraph.component';
import { HostDirective } from './directives/host/host.directive';
import { ChecklistParagraphComponent } from './components/paragraphs/checklist-paragraph/checklist-paragraph.component';
import { TextAndImageParagraphComponent } from './components/paragraphs/text-and-image-paragraph/text-and-image-paragraph.component';
import { InstructionListParagraphComponent } from './components/paragraphs/instruction-list-paragraph/instruction-list-paragraph.component';
import { InstructionByUuidComponent } from './components/instruction-by-uuid/instruction-by-uuid.component';
import { InstructionByAliasComponent } from './components/instruction-by-alias/instruction-by-alias.component';
import { InstructionItemComponent } from './components/instruction-item/instruction-item.component';
import { FilesParagraphComponent } from './components/paragraphs/files-paragraph/files-paragraph.component';
import { InstructionPrintDialogComponent } from './components/instruction/print-dialog/print-dialog.component';

@NgModule({
    declarations: [
        InstructionComponent,
        TextParagraphComponent,
        ImageParagraphComponent,
        HostDirective,
        ChecklistParagraphComponent,
        TextAndImageParagraphComponent,
        InstructionListParagraphComponent,
        InstructionByUuidComponent,
        InstructionByAliasComponent,
        InstructionItemComponent,
        FilesParagraphComponent,
        InstructionPrintDialogComponent
    ],
    imports: [
        SharedModule,
        FormsModule,
        PlaceholderModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatDialogModule,
        FullScreenDialogModule
    ]
})
export class InstructionModule { }
