import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';

import { environment } from 'src/environments/environment';

export type ResourceType = 'image' | 'file';

export interface ResourceEntry {
    type: ResourceType;
    url: string;
    file: File;
    objectUrl: string;
}

export interface ResourceTypePath {
    type: ResourceType;
    url: string;
}

@Injectable()
export class ResourceService implements OnDestroy {

    private resources: Map<string, ResourceEntry> = new Map();

    private dispose$: Subject<void> = new Subject();

    constructor(
        private http: HttpClient) { }

    ngOnDestroy() {
        this.disposeAllResources();

        this.dispose$.complete();
    }

    private buildResourceKey(type: ResourceType, url: string): string {
        return `${type}|${url}`;
    }

    private disassembleResourceKey(key: string): ResourceTypePath {
        const keyParts = key.split('|');

        return {
            type: keyParts[0],
            url: keyParts[1]
        } as ResourceTypePath;
    }

    private disposeResourceEntry(resource: ResourceEntry): void {
        URL.revokeObjectURL(resource.objectUrl);
    }

    public disposeAllResources(): void {
        // Cancel any possible pending resource operations
        this.dispose$.next();

        for (const resource of this.resources.values()) {
            this.disposeResourceEntry(resource);
        }

        this.resources.clear();
    }

    public disposeResource(type: ResourceType, pathOrUrl: string): void {
        const url = this.buildResourceUrl(type, pathOrUrl);
        const key = this.buildResourceKey(type, url);
        const resource = this.resources.get(key);

        if (!resource) {
            return;
        }

        this.disposeResourceEntry(resource);

        this.resources.delete(key);
    }

    public hasResource(type: ResourceType, pathOrUrl: string): boolean {
        const url = this.buildResourceUrl(type, pathOrUrl);
        const key = this.buildResourceKey(type, url);

        return this.resources.has(key);
    }

    public getCachedResources(): ResourceTypePath[] {
        let results: ResourceTypePath[] = [];

        for (const key of this.resources.keys()) {
            results.push(this.disassembleResourceKey(key));
        }

        return results;
    }

    public buildResourceUrl(type: ResourceType, path: string): string {
        if (path.startsWith('http')) {
            return path;
        } else {
            return environment.instructionApiBaseUri +
                (type === 'file' ? `private-file?path=${path}` : path);
        }
    }

    public isPrivateResource(pathOrUrl: string): boolean {
        return pathOrUrl.includes('/system/files/');
    }

    public async getResource(type: ResourceType, pathOrUrl: string, name?: string, remoteOnly: boolean = false): Promise<ResourceEntry> {
        const url = this.buildResourceUrl(type, pathOrUrl);
        const key = this.buildResourceKey(type, url);
        const cachedResource = this.resources.get(key);

        const resource = !remoteOnly && cachedResource ?
            cachedResource :
            await this.fetchRemoteResource(type, url, name);

        return { ...resource };
    }

    private async fetchRemoteResource(type: ResourceType, url: string, name?: string): Promise<ResourceEntry> {
        const key = this.buildResourceKey(type, url);

        // FYI: If the resources are disposed when the request is still in progress
        //      request will be canceled and firstValueFrom throws EmptyError,
        //      thus the returning promise is rejected.
        const blob = await firstValueFrom(
            this.http.get(url, {
                responseType: 'blob'
            })
                .pipe(takeUntil(this.dispose$))
        );

        const file = new File([blob], name ?? '', {
            type: blob.type
        });

        const resource: ResourceEntry = {
            type,
            url,
            file,
            objectUrl: URL.createObjectURL(file)
        };

        this.resources.set(key, resource);

        return resource;
    }
}
