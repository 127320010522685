import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
})
export class SitemapComponent implements OnInit {
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http
      .get(
        `${environment.instructionApiBaseUri}sitemap.xml/generate`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('drupal-cms-client-access_token')}`,
          },
          withCredentials: true,
        },
      )
      .subscribe((response) => {
        window.location.href = window.URL.createObjectURL(response);
      });
  }

}
