export const environment = {
    production: true,
    version: '20240319.2', // Version number placeholder, will be replaced with actual version in pipelines
    environmentName: 'PROD',
    applicationInsightsInstrumentationKey: 'a31cb758-ac82-408b-ab62-bea4afd9e77d',
    backendBaseUri: 'https://backend.headpower.fi/',
    accountBaseUri: 'https://account.headpower.fi/',
    portalBaseUri: 'https://portal.headpower.fi/',
    backendPortalUri: 'https://backend.headpower.fi/Portal.BackEnd/',
    productApiKey: 'E3920412-5086-4CB6-B36E-2145D4397D30',
    instructionApiBaseUri: 'https://cms-ohjeistot.headpower.fi/',
};
