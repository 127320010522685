import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanDeactivate<CanComponentDeactivate> {
  private hasAccessRight = false;
  private hasAccessRightCached = false;

  constructor(private oauthService: OAuthService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const authTicket = next.queryParams.authTicket;

    if (authTicket !== undefined) {
      this.oauthService.customQueryParams = { acr_values: `authTicket:${authTicket}` };
      this.router.navigate(['/login']);
      this.hasAccessRight = false;
      this.hasAccessRightCached = false;

      return false;
    }

    if (this.oauthService.hasValidIdToken()) {
      if (this.hasAccessRightCached) {
        if (this.hasAccessRight) {
          return true;
        }

        this.router.navigate(['/no-usage-right']);

        return false;
      }

      this.hasAccessRight = true;
      this.hasAccessRightCached = true;

      return this.hasAccessRight;
    }

    this.hasAccessRight = false;
    this.hasAccessRightCached = false;

    sessionStorage.setItem('after_auth_redirect_url', state.url);

    this.router.navigate(['/login']);

    return false;
  }

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.canDeactivate();
  }
}
