import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EmptyError, Subject, first, takeUntil } from 'rxjs';

import { HostDirective } from '../../directives/host/host.directive';
import { ResourceEntry, ResourceService } from 'src/app/modules/shared/services/resource.service';

@Component({
    selector: 'app-instruction-item',
    templateUrl: './instruction-item.component.html',
    styleUrls: ['./instruction-item.component.scss']
})
export class InstructionItemComponent implements AfterViewInit, OnInit, OnDestroy {

    @Input() public showCollapseControls: boolean;
    @Input() public label: string;
    @Input() public id: string;
    @Input() public thumbnail?: string;
    @Input() public collapsed: boolean;
    @Input() public print: boolean;
    @Input() @HostBinding('class.is-first') public isFirst: boolean;
    @Input() @HostBinding('class.is-last') public isLast: boolean;

    @Output() emitPrint: EventEmitter<string> = new EventEmitter();

    @ViewChild(HostDirective, { static: true }) public hostDirective: HostDirective;

    public thumbnailSource?: SafeResourceUrl;
    public thumbnailLoading: boolean = false;

    private destroy$: Subject<void> = new Subject();

    constructor(
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private resourceService: ResourceService) { }

    ngOnInit() {
        this.route.fragment
            .pipe(
                first()
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(fragment => {
                this.collapsed = this.showCollapseControls && fragment !== this.id;
            });

        if (this.thumbnail && !this.print) {
            this.fetchThumbnail();
        }
    }

    ngAfterViewInit() {
        this.route.fragment
            .pipe(
                first()
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(fragment => {
                if (fragment === this.id) {
                    this.scrollToFragment(fragment, true);
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public toggleCollapse() {
        this.collapsed = !this.collapsed;
    }

    public scrollToFragmentFromLink() {
        setTimeout(() => {
            this.scrollToFragment(this.id);
        }, 250);
    }

    public printItem() {
        this.emitPrint.emit(this.id);
    }

    private async fetchThumbnail() {
        if (this.resourceService.isPrivateResource(this.thumbnail)) {
            this.thumbnailLoading = true;

            let resource: ResourceEntry;

            try {
                resource = await this.resourceService.getResource('image', this.thumbnail);
            }
            catch (error) {
                // EmptyError is thrown if service disposes the resources while request is in progress.
                //
                // This happens when user navigates away from the instruction while downloading,
                // so just ignore the error and return.
                if (error instanceof EmptyError) {
                    return;
                }

                // Otherwise ignore error and continue execution
            }

            const url = resource?.objectUrl ?? '';

            this.thumbnailSource = this.sanitizer.bypassSecurityTrustResourceUrl(url);

            this.thumbnailLoading = false;
        }
        // Public resource, no access token header needed
        else {
            const url = this.resourceService.buildResourceUrl('image', this.thumbnail);

            this.thumbnailSource = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
    }

    private scrollToFragment(fragment: string, auto: boolean = false) {
        // Offset scroll by 32 pixels to make sure the title doesn't go under the task bar.
        window.scroll({
            top: document.getElementById(fragment).offsetTop - 32,
            behavior: auto ? 'auto' : 'smooth'
        });
    }
}
