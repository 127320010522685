import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeFi from '@angular/common/locales/fi';
import { AppOAuthStorage } from '@headpower/angular-oauth2-oidc-extensions';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CookieStorage } from 'cookie-storage';
import { ThemeService, getOldTheme } from '@headpower/layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CatalogModule } from './modules/catalog/catalog.module';
import { CoreModule } from './modules/core/core.module';
import { InstructionModule } from './modules/instruction/instruction.module';
import { SharedModule } from './modules/shared/shared.module';
import { TagModule } from './modules/tag/tag.module';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { HpoErrorHandler } from './shared/handlers/hpo-errorhandler.service';
import { environment } from 'src/environments/environment';
import { FileModule } from './modules/file/file.module';

registerLocaleData(localeFi, 'fi-FI');

export function initTheme(themeService: ThemeService): Function {
    return () => themeService.init(getOldTheme());
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        InstructionModule,
        CatalogModule,
        TagModule,
        FileModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
        })
    ],
    providers: [
        {
            provide: OAuthStorage,
            useFactory: () =>
                new AppOAuthStorage(
                    'drupal-cms-client',
                    new CookieStorage({ path: '/' }),
                )
        },
        { provide: ErrorHandler, useClass: HpoErrorHandler },
        { provide: APP_INITIALIZER, useFactory: initTheme, deps: [ThemeService], multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }