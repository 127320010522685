import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { merge } from 'rxjs';
import { AppService } from '../../../../app.service';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    styleUrls: ['./login-redirect.component.scss'],
    templateUrl: './login-redirect.component.html',
})
export class LoginRedirectComponent implements OnInit {
    private defaultRedirectPage = '';

    constructor(
        private oAuthService: OAuthService,
        private activatedRoute: ActivatedRoute,
        private appService: AppService) {
    }

    ngOnInit() {
        this.activatedRoute.queryParams
            .subscribe((params: Params) => {
                const loggedParam = params.loggedIn;

                if (!!!loggedParam) {
                    this.initialLogin();

                    return;
                }

                merge(this.oAuthService.events, this.oAuthService.events.pipe(filter(event => event.type === 'user_profile_loaded')))
                    .subscribe(event => {
                        if (event instanceof OAuthErrorEvent) {
                            const errorEvent = event as OAuthErrorEvent;

                            this.handleError(new Error(errorEvent.type));

                            return;
                        }

                        this.redirectAuthenticatedUser();

                    },
                        () => {
                            this.handleError(new Error('Unknown error'));
                        });
            });
    }

    private redirectAuthenticatedUser() {
        const redirectUrl = sessionStorage.getItem('after_auth_redirect_url') || this.defaultRedirectPage;

        this.appService
            .sendPageUsageStatistics(redirectUrl)
            .pipe(
                timeout(2000), // Give statistics 2 seconds to respond
                catchError(() => of('PageUsageStatistics took too long')),
            )
            .subscribe(() => {
                // Redirect after usage statistics have been added
                window.location.replace(document.baseURI + redirectUrl);
            }, () => {
                // Redirect even if we get an error
                window.location.replace(document.baseURI + redirectUrl);
            });
    }

    private initialLogin() {
        if (this.oAuthService.hasValidIdToken()) {
            this.redirectAuthenticatedUser();

            return;
        }

        if (this.activatedRoute.snapshot.queryParams.redirectUrl) {
            sessionStorage.setItem('after_auth_redirect_url', this.activatedRoute.snapshot.queryParams.redirectUrl);
        }

        this.oAuthService.initImplicitFlow();
    }

    private handleError(error: Error) {
        const errorMessage = encodeURIComponent(btoa(`Login failed. (${error.message})`));

        window.location.href = `${document.baseURI}assets/error.html#${errorMessage}`;
    }
}
