<hpo-content-layout [useAnchors]="true"
    [mobile]="mobile"
    (scrolling)="scrolling($event)">

    <div class="instruction"
        [class.instruction--mobile]="mobile">

        <ng-container *ngIf="instruction">

            <h1 *ngIf="printState !== 'item'"
                class="instruction__title">

                {{instruction.attributes.name}}

                <a routerLink="."
                    fragment="">
                    <mat-icon>link</mat-icon>
                </a>

                <a (click)="openPrintDialog()">
                    <mat-icon>print</mat-icon>
                </a>

            </h1>

            <a *ngIf="changelogEntries && changelogEntries.length > 0"
                class="instruction__changelog"
                (click)="showChangelog()">
                {{ 'default.changelogInstruction' | blah }}
            </a>

            <ul class="instruction__tags">
                <li *ngFor="let tag of instruction.tags">
                    <a [routerLink]="['/tag', tag.id]">{{tag.attributes.name}}</a>
                </li>
            </ul>

        </ng-container>

        <ng-container *ngIf="loading">
            <hpo-placeholder type="row"></hpo-placeholder>
            <hpo-placeholder type="row"></hpo-placeholder>
            <hpo-placeholder type="row"></hpo-placeholder>
        </ng-container>

        <ng-template appHost></ng-template>

        <app-copyright></app-copyright>

    </div>

</hpo-content-layout>

<div class="print-note"
    [class.printing]="printState">

    <h4 *ngIf="printState">
        {{ 'default.printing' | blah }}...
    </h4>

    <h4 *ngIf="!printState">
        {{ 'default.useInstructionPrintButton' | blah }}
    </h4>

</div>