import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FloatingActionButtonService } from '@headpower/components';
import { ApplicationInsightsService, CurrentUser, LayoutBreakpointService, MenuItem } from '@headpower/layout';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Subject } from 'rxjs';
import { map, filter, mergeMap, takeUntil } from 'rxjs/operators';
import { BlahService } from '@headpower/blah-ng';

import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    public productId = 592;
    public currentUser = new CurrentUser();
    public locationUri: string;
    public portalBaseUri: string;
    public backendPortalUri: string;
    public accountBaseUri: string;
    public backendBaseUri: string;
    public menuItems: Array<MenuItem> = [];
    public claims: { [key: string]: any };
    public environmentVariables: any;
    public hideLayoutMenu: boolean;
    public productionMode: boolean;
    public mobile: boolean = false;
    public appVersion: string = environment.version;

    private destroy$: Subject<void> = new Subject();

    public get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }
    private _isLoggedIn: boolean = false;

    constructor(
        private oauthService: OAuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private layoutBreakpointService: LayoutBreakpointService,
        private fabService: FloatingActionButtonService,
        private appInsightsService: ApplicationInsightsService,
        private blahService: BlahService,
    ) {
        this.environmentVariables = {
            ...environment,
            config: {
                environmentName: environment.environmentName
            }
        };

        this.portalBaseUri = this.environmentVariables.portalBaseUri;
        this.backendPortalUri = this.environmentVariables.backendPortalUri;
        this.accountBaseUri = this.environmentVariables.accountBaseUri;
        this.productionMode = this.environmentVariables.production;
        this.backendBaseUri = this.environmentVariables.backendBaseUri;

        this.configAuthentication();
    }

    ngOnInit() {
        // Observe device screen size and change the layout accordingly
        this.layoutBreakpointService.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => this.mobile = result.handset);

        // Initialize Application Insights
        this.appInsightsService.init(this.environmentVariables.applicationInsightsInstrumentationKey, true);

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .pipe(
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }

                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data),
            )
            .subscribe((event) => {
                this.fabService.resetState();
                this.mobile = false;

                const fullTitle = 'HeadPower Ohjeistot';
                this.titleService.setTitle(
                    event.title ? `${event.title} | ${fullTitle}` : fullTitle,
                );
                this.locationUri = document.location.href;
            });

        if (this.isLoggedIn) {
            this.menuItems = [
                new MenuItem(
                    this.blahService.blah('default.catalogLanding.home.title'),
                    'catalog/headpower',
                    {
                        icon: 'home',
                    },
                ),
                new MenuItem(
                    this.blahService.blah('default.catalogLanding.search.title'),
                    'catalog/search',
                    {
                        icon: 'search',
                    },
                ),
                new MenuItem(
                    this.blahService.blah('default.catalogLanding.customer.title'),
                    'catalog/supplier',
                    {
                        icon: 'list',
                    },
                ),
                new MenuItem(
                    this.blahService.blah('default.catalogLanding.changeLog.title'),
                    'changelog',
                    {
                        icon: 'list',
                    },
                )
            ];
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    handleLogoutClicked() {
        this.oauthService.logOut();
    }

    private configAuthentication() {
        this.oauthService.configure(this.constructAuthConfig());
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this.oauthService.loadDiscoveryDocumentAndTryLogin();
        this.oauthService.setupAutomaticSilentRefresh();

        // Catch important events and perform appropriate actions
        this.oauthService.events.subscribe((event) => {
            switch (event.type) {
                case 'discovery_document_loaded':
                case 'token_received':
                    this.oauthService.loadUserProfile();
                    break;

                case 'user_profile_loaded':
                    this.setClaimProperties();
                    this._isLoggedIn = true;
                    break;

                case 'session_terminated':
                    console.log('Authentication session terminated - logging out');
                    this._isLoggedIn = false;
                    this.handleLogoutClicked();
                    break;
            }
        });

        if (this.oauthService.hasValidAccessToken() && this.oauthService.getIdentityClaims()) {
            this.setClaimProperties();
            this._isLoggedIn = true;
        }
    }

    private constructAuthConfig(): AuthConfig {
        const authConf = new AuthConfig();

        authConf.redirectUri = `${window.location.origin}/login?loggedIn=true`;
        authConf.silentRefreshRedirectUri = `${window.location.origin}/silent-refresh.html`;
        authConf.clientId = 'cms_ui';
        authConf.scope = 'openid profile email roles offline_access headpower:app headpower:api';
        authConf.showDebugInformation = false;
        authConf.sessionChecksEnabled = true;
        authConf.issuer = `${this.environmentVariables.accountBaseUri}identity`;
        authConf.logoutUrl = `${this.environmentVariables.accountBaseUri}identity/connect/endsession?id_token={{id_token}}`;

        return authConf;
    }

    private setClaimProperties() {
        this.claims = this.oauthService.getIdentityClaims();

        /* tslint:disable:no-string-literal */
        this.currentUser.userId = this.claims['sub'];
        this.currentUser.givenName = this.claims.given_name;
        this.currentUser.familyName = this.claims.family_name;
        this.currentUser.email = this.claims.email;
        this.currentUser.picture = this.claims.picture;
        this.currentUser.accessToken = this.oauthService.getAccessToken();
        this.currentUser.companyName = this.claims['headpower:company_name'];
    }
}