import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef, QueryList, ComponentRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContentBlockComponent, ContentLayoutComponent, FullScreenDialogService } from '@headpower/components';
import { LayoutBreakpointService } from '@headpower/layout';
import { Subject, takeUntil } from 'rxjs';

import { AppService } from '../../../../app.service';
import { InstructionFormatterService } from '../../../core/services/instruction-formatter/instruction-formatter.service';
import { HostDirective } from '../../directives/host/host.directive';
import { ParagraphComponent } from '../../models/paragraph-component/paragraph-component';
import { ChecklistParagraphComponent } from '../paragraphs/checklist-paragraph/checklist-paragraph.component';
import { FilesParagraphComponent } from '../paragraphs/files-paragraph/files-paragraph.component';
import { ImageParagraphComponent } from '../paragraphs/image-paragraph/image-paragraph.component';
import { InstructionItemComponent } from '../instruction-item/instruction-item.component';
import { InstructionListParagraphComponent } from '../paragraphs/instruction-list-paragraph/instruction-list-paragraph.component';
import { TextAndImageParagraphComponent } from '../paragraphs/text-and-image-paragraph/text-and-image-paragraph.component';
import { TextParagraphComponent } from '../paragraphs/text-paragraph/text-paragraph.component';
import { ChangelogDialogComponent, ChangelogDialogDataInterface } from 'src/app/modules/shared/components/changelog-dialog/changelog-dialog.component';
import { ChangelogService } from 'src/app/modules/shared/services/changelog.service';
import { Changelog } from 'src/app/modules/shared/models/changelog';
import { ResourceService } from 'src/app/modules/shared/services/resource.service';
import { InstructionPrintItem, InstructionPrintDialogData, InstructionPrintDialogResultData } from './print-dialog/print-dialog.model';
import { InstructionPrintDialogComponent } from './print-dialog/print-dialog.component';

@Component({
    selector: 'app-instruction',
    templateUrl: './instruction.component.html',
    styleUrls: ['./instruction.component.scss'],
    providers: [ResourceService]
})
export class InstructionComponent implements OnInit, OnDestroy {

    @Input() public instructionEmitter: EventEmitter<any>;

    public mobile: boolean = false;
    public loading: boolean = true;

    public instruction?: any;

    public printState?: 'instruction' | 'item';
    public printItemIds?: string[];

    public changelogEntries: Changelog[];

    @ViewChild(HostDirective, { static: true }) public hostDirective: HostDirective;
    @ViewChild(ContentLayoutComponent) public contentLayoutComponent: ContentLayoutComponent;

    private response?: { [key: string]: any };

    private paragraphTypeToComponentMap = new Map<string, any>([
        ['paragraph--text', TextParagraphComponent],
        ['paragraph--image', ImageParagraphComponent],
        ['paragraph--files', FilesParagraphComponent],
        ['paragraph--checklist', ChecklistParagraphComponent],
        ['paragraph--text_and_image', TextAndImageParagraphComponent],
        ['paragraph--instruction_list', InstructionListParagraphComponent]
    ]);

    private componentRefs: ComponentRef<InstructionItemComponent>[] = [];

    private destroy$: Subject<void> = new Subject();

    constructor(
        private instructionFormatter: InstructionFormatterService,
        private layoutBreakpointService: LayoutBreakpointService,
        private appService: AppService,
        private dialog: MatDialog,
        private fullScreenDialog: FullScreenDialogService,
        private resourceService: ResourceService,
        private changelogService: ChangelogService) { }

    ngOnInit() {
        this.layoutBreakpointService.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => this.mobile = result.handset);

        this.instructionEmitter
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => {
                this.response = result;
                this.updateInstructionView();
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();

        // FYI: Commented out as dispose on destroy doesn't do anything at the moment
        //this.dispose(true);

        this.appService.setAppTitle('');
    }

    private dispose(onDestroy: boolean = false) {
        // Dispose all resources if component is not destroyed but dispose is requested
        if (!onDestroy) {
            this.resourceService.disposeAllResources();
        }
    }

    /**
     * Detect when scrolling to a new section and open it if it's not open already
     * @param evt Scrolling event from ContentLayoutComponent
     */
    public scrolling(evt: { scrolling: boolean; section: string }): void {
        const index = this.componentRefs.findIndex(o => o.instance.id === evt.section);

        if (this.componentRefs[index].instance.collapsed) {
            // Move to section again after one offset refresh cycle (250 ms).
            // This finds correct position even if content has changed while scrolling (expand/collapse).
            setTimeout(() => {
                this.contentLayoutComponent.moveToSection(index, evt.section);
            }, 300);
        }

        this.componentRefs[index].instance.collapsed = false;
    }

    private updateInstructionView() {
        if (this.instruction) {
            this.resetView();
        }

        if (this.response) {
            this.createView();
        } else {
            this.loading = true;
            this.dispose();
        }
    }

    private resetView() {
        this.hostDirective.viewContainerRef.clear();
        this.componentRefs = [];

        this.contentLayoutComponent.contentBlocks = new QueryList<ContentBlockComponent>();
        this.contentLayoutComponent.refresh();

        this.instruction = undefined;
        this.changelogEntries = undefined;
    }

    private createView() {
        // parse instructions
        this.instruction = this.instructionFormatter.createInstruction(this.response);

        // After the instruction is created/loaded we can log it's opening
        this.appService.sendInstructionOpeningStatistics(this.instruction.attributes.path.alias)
            .subscribe();

        const visibleInstructionItems = !this.printState ?
            this.instruction.instructionItems :
            this.instruction.instructionItems.filter(o => this.isPrintingItem(o.id));

        visibleInstructionItems.forEach((instructionItem: any, index: number) => {
            const isFirst = index === 0;
            const isLast = index === visibleInstructionItems.length - 1;

            this.createContentBlock(instructionItem);
            this.createInstructionItem(instructionItem, this.response, isFirst, isLast);
        });

        this.contentLayoutComponent.refresh();

        this.appService.setAppTitle(this.instruction.attributes.name);

        this.loading = false;

        if (!this.printState) {
            this.fetchChangelogEntries();
        }
    }

    private createContentBlock(instructionItem: any) {
        const contentBlockReference = this.hostDirective.viewContainerRef.createComponent(ContentBlockComponent);

        contentBlockReference.instance.title = instructionItem.attributes.name;
        contentBlockReference.instance.id = instructionItem.id;
        contentBlockReference.instance.anchor = instructionItem.id;

        this.contentLayoutComponent.contentBlocks.reset([
            ...this.contentLayoutComponent.contentBlocks.toArray(),
            contentBlockReference.instance
        ]);
    }

    private createInstructionItem(instructionItem: any, response: any, isFirst: boolean, isLast: boolean) {
        const instructionItemReference = this.hostDirective.viewContainerRef.createComponent(InstructionItemComponent);

        // fill in instance attributes
        const { name, collapse } = instructionItem.attributes;
        instructionItemReference.instance.showCollapseControls = collapse;
        instructionItemReference.instance.collapsed = collapse;
        instructionItemReference.instance.label = name;
        instructionItemReference.instance.id = instructionItem.id;
        instructionItemReference.instance.isFirst = isFirst;
        instructionItemReference.instance.isLast = isLast;
        instructionItemReference.instance.print = false;

        // printemitter
        instructionItemReference.instance.emitPrint
            .subscribe(id => {
                this.printInstruction(false, [{ id }]);
            });

        // set printing instructions
        if (this.printState) {
            instructionItemReference.instance.showCollapseControls = false;
            instructionItemReference.instance.collapsed = false;
            instructionItemReference.instance.print = true;
        }

        // thumbnail, if applicable
        if (instructionItem.thumbnail) {
            instructionItemReference.instance.thumbnail = instructionItem.thumbnail.attributes.uri.url;
        }

        this.componentRefs.push(instructionItemReference);

        // add paragraphs
        instructionItem.paragraphs.forEach((paragraph: any) =>
            this.createParagraph(
                instructionItemReference.instance.hostDirective.viewContainerRef,
                paragraph,
                response
            )
        );
    }

    private createParagraph(viewContainerReference: ViewContainerRef, paragraph: any, response: any) {
        const paragraphComponent = this.paragraphTypeToComponentMap.get(paragraph.type);
        const paragraphReference = viewContainerReference.createComponent<ParagraphComponent>(paragraphComponent);

        paragraphReference.instance.data = { self: paragraph, full: response };
    }

    private isPrintingItem(id: string) {
        return this.printItemIds?.includes(id) ?? true;
    }

    public openPrintDialog() {
        const items = this.contentLayoutComponent.contentBlocks
            .map(o => ({
                id: o.id,
                title: o.title
            }) as InstructionPrintItem);

        const dialogData: InstructionPrintDialogData = {
            mobile: false,
            items
        };

        let dialogRef: MatDialogRef<any, InstructionPrintDialogResultData>;

        if (this.mobile) {
            dialogData.mobile = true;

            dialogRef = this.fullScreenDialog.open(InstructionPrintDialogComponent, {
                data: {
                    ...dialogData,
                    dialogTitle: 'default.printInstruction',
                    actionLabel: 'default.print'
                }
            });
        } else {
            dialogRef = this.dialog.open(InstructionPrintDialogComponent, {
                width: '500px',
                disableClose: true,
                data: dialogData
            });
        }

        dialogRef.afterClosed()
            .subscribe(result => {
                if (result) {
                    this.printInstruction(true, result.selectedItems);
                }
            });
    }

    private printInstruction(full: boolean, items: InstructionPrintItem[]) {
        // Change ui to print mode
        this.printState = full ? 'instruction' : 'item';
        this.printItemIds = items.map(o => o.id);
        this.updateInstructionView();

        window.onafterprint = () => {
            window.onafterprint = undefined;

            // Reset ui back to normal mode
            this.printState = undefined;
            this.printItemIds = undefined;
            this.updateInstructionView();
        };

        setTimeout(() => {
            window.print();
        }, 2500);
    }

    private fetchChangelogEntries() {
        this.changelogService.getChangelogEntries(this.instruction.id)
            .subscribe((changelogEntries: any) => {
                this.changelogEntries = changelogEntries.data;
            });
    }

    public showChangelog() {
        let dialogRef;
        let dialogData = {
            changelogEntries: this.changelogEntries,
            dialogTitle: 'default.changelogTitle',
            mobile: this.mobile
        }
        if (this.mobile) {
            dialogRef = this.fullScreenDialog.open(ChangelogDialogComponent, {
                autoFocus: false,
                data: dialogData as ChangelogDialogDataInterface
            });
        } else {
            dialogRef = this.dialog.open(ChangelogDialogComponent, {
                maxWidth: '960px',
                maxHeight: '90vh',
                autoFocus: false,
                data: dialogData
            });
        }

        dialogRef.afterClosed()
            .subscribe(result => { });
    }
}
